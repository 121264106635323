*, *::after, *::before {
    box-sizing: border-box;

  }

  :root {
    --colour-primary: #1D9BF0;
    --colour--hover: #2facff;
    --colour-headings:#f7f7f7;
    --colour-background: radial-gradient(circle, rgba(25,39,73,1) 0%, rgba(15,23,42,1) 100%);
  }

  html {
    font-size: 62.5%;
  }


  body {
    font-family: "Manrope", sans-serif;
    overflow-x: hidden;
  }

  h1, h2, h3{
    color: var(--colour-headings);
  }

  h3 {
    font-size: 2rem;
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }

  .contentContainer {
    max-width: 345px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    .contentContainer {
      max-width: 689px;
    }
  }
  @media screen and (min-width: 1024px) {
    .contentContainer {
      max-width: 980px;
    }
  }
  @media screen and (min-width: 1280px) {
    .contentContainer {
      max-width: 1110px;
    }
  }
  /* Navigation bar  */
  .navBarContainer {
    position: relative;
    z-index: 1;
  }

  p {
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 800;
  }

  .navBar {
    /* background: var(--colour-background); */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative;
  }

  .navBar-svgHeading {
    font-size: 2.4rem;
    text-align: center;
    margin: 2.4rem 0;
  }
  .navBar-svgHeading path {
    fill: white;
    stroke: white;
    stroke-width: 0;
    stroke-dasharray: 911;
    stroke-dashoffset: 911;
    animation: draw 1s ease-out forwards;
  }

  @keyframes draw {
    to {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
    }
  }
  .icon {
    margin: 0 1rem;
    z-index: 1;
    cursor: pointer;
    fill: #FFF;
    opacity: 0.70;
    transition: all 0.2s;
  }

  a:hover .icon {
    fill: var(--colour--hover);
  }

  @media screen and (min-width: 768px) {
    .navBar {
      flex-direction: row;
    }
  }
  .line {
    display: none;
    background-color: rgba(255, 255, 255, 0.2);
    height: 1px;
    margin: 0 auto;
    width: 100%;
  }

  /* Header block  */
  header {
    position: relative;
    margin-bottom: 2rem;
    z-index: 1;
  }

  .homeBlock {
    padding-top: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .homeBlock__typedHeadingContainer {
    width: 96%;
    margin-bottom: 2.4rem;
    padding-bottom: 1px;
    margin-top: 5rem;
    text-align: center;


  }

  .homeBlock__typeSizer{
    height: 18rem;
    animation-name: move;
    animation-duration: 10s;
    animation-iteration-count: infinite;

  }

  @keyframes move {
    0% {
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(15px);
    }
    100%{
      transform: translateY(-15px);
    }
  }

  .homeBlock__typedHeading {
    color: var(--colour-headings);
    border: 0;
    outline: 0;
    text-align: left;
    font-size: 3.3rem;
    font-family: Manrope;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1.286px;
    text-align: center;
    margin-bottom: 2.4rem;
    padding-bottom: 1px;

  }

  #underlineHeading {
    color: var(--colour-primary);
    position: relative;
  }
  /* #underlineHeading:before {
    color: var(--colour-primary);
    position: absolute;
    content: "";
    width: 100%;
    height: 5px;
    top: 40px;
    background-color: var(--colour--hover);
  } */

  .homeBlock__flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .homeBlock__selfie {
    height: 204px;
    width: 153.6px;
  }

  .homeBlock__paragraph {
    color: #FFF;
    text-align: center;
    font-size: 1.5rem;
    font-family: Manrope;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 2.2rem;
    opacity: 0.75;
    margin-top: 2.4rem;
  }

  .homeBlock__paragraph-link {
    color: #FFF;
    line-height: 25px;
    opacity: 0.9;
    transition: all 0.2s;
    font-size: 1.5rem;
    font-family: Manrope;
    font-weight: 500;
    line-height: 25px;
    text-decoration: underline;
  }

  li{
    color: #FFF;
    text-align: left;
  }

  .homeBlock__paragraph-link:hover {
    color: var(--colour-primary);
    opacity: 1;
  }

  .homeBlock__svgContainer{
    margin-bottom: 2rem;
    width: 30rem;
    height: 30rem;
    cursor: pointer;
    transition: all 1s;
  }

  .homeBlock__N {
    position: absolute;
    z-index: 1;
    width: 30rem;
    height: 30rem;
    fill: none;
    stroke: rgba(255, 255, 255, 0);
    stroke-width: 5;
    stroke-dasharray: 60 172;
    stroke-linecap: round;
    animation: oj 4s linear infinite;
    transition: all 0.3s;
  }

  .homeBlock__svgContainer:hover .homeBlock__N {
    scale: 1.1;
  }

  .homeBlock__N path {
    fill: #ffffff;
    opacity: 0.95;
  }
  .homeBlock__N:nth-last-of-type(1) {
    filter: blur(15px);
    transform: translate3d(0, 0, 0);
    z-index: 0;
  }
  .homeBlock__N:nth-last-of-type(1) path {
    fill: rgba(255, 255, 255, 0.6);
    stroke: url(#col);
    z-index: 1;
  }

  @keyframes oj {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 232;
    }
  }

  @media screen and (min-width: 768px) {
    .line {
      display: block;
    }
    .homeBlock__typedHeadingContainer {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 2.4rem;
      padding-top: 1px;
      margin-top: 12.8rem;
    }
    .homeBlock__typedHeading {
      width: 39.6rem;
      font-size: 5rem;
      line-height: 58px;
      letter-spacing: 2px;
      margin-bottom: 2.4rem;
      text-align: center;
    }
    #underlineHeading:before {
      top: 60px;
    }

    .homeBlock__paragraph {
      margin: 0 auto;
      margin-bottom: 3rem;
      margin-top: 2.4rem;
      opacity: 0.75;
      line-height: 25px;
      width: 34.9rem;
    }

  }
  @media screen and (min-width: 1024px) {
    .homeBlock {
      height: 65rem;
      display: block;
      max-width: 1110px;
      margin: 0 auto;
    }
    .homeBlock__typedHeadingContainer {
      margin-left: 0;
      text-align: left;
      width: 50%;
      margin-bottom: 2.4rem;
    }

    .homeBlock__flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    #underlineHeading:before {
      top: 60px;
    }
    .homeBlock__paragraph {
      margin-left: 0;
      text-align: left;
      width: 37rem;
    }

  .homeBlock__svgContainer {
    width: 40rem;
    height: 40rem;
  }

  .homeBlock__N {
    width: 40rem;
    height: 40rem;
  }

  .homeBlock__typeSizer{
    height: 24rem;
  }

  @media screen and (min-width:1280px) {
    .homeBlock__typeSizer{
      height: 18rem;
    }
  }

  @keyframes oj {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 232;
    }
  }
  }
  /* Technology block  */
  .technologyBlock {
    width: 100%;
    margin: 0 auto;
    background: rgb(255, 255, 252, 0);
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 2.4rem;
  }

  .technologyBlock__header {
    color: var(--colour-headings);
    font-size: 2.5rem;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0 auto;
    margin-bottom: 3.2rem;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
    text-align: center;

  }

  .technologyBlock__itemContainer h3{
    opacity: 0.75;
    font-size: 1.8rem;
  }

  .technologyBlock__showcaseContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .technologyBlock__itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .technologyBlock__itemContainer h3{
    opacity: 0.75;
    text-align: center;
  }

  .technologyBlock__itemContainer img, .technologyBlock__itemContainer svg {
    width: 140px;
  }

  .technologyBlock__itemContainer.technologyBlock-mongo img {
    width: 180px;
    transform: translate(0px, 5px);
  }


  /* SVG animations  */
  .technologyBlock__sassSVG path {
    stroke: rgb(255, 255, 255, 0.9);
    fill: rgba(255, 255, 255, 0);
    stroke-width: 2;
    stroke-dashoffset: 4701.2465820313;
    stroke-dasharray: 4701.2465820313;
  }

  #technologyBlock__sassSVG{
    stroke-width: 2;
    stroke: rgb(255, 255, 255, 1);
    stroke-dashoffset: 4701.2465820313;
    stroke-dasharray: 4701.2465820313;

    animation: sass 3s ease-out forwards;
    animation-delay: .3s;
    transition: all 0.2s;
  }

  #technologyBlock__sassSVGHover{
    animation: sassHover 2s ease-out forwards;
  }

  @keyframes sass {
    0% {
      stroke-dashoffset: 4701.2465820313;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }

  @keyframes sassHover {
    0% {
      fill: rgb(255, 255, 255, 0.9);
      stroke: rgba(255, 255, 255, 1);
    }

    100% {
      fill: rgb(205, 103, 153, 1);
      stroke: rgb(205, 103, 153, 1);
      stroke-dashoffset: 0;
    }
  }

  .technologyBlock__HtmlSvg{
    fill: rgb(255, 255, 255, 0);
    transition: all 0.2s;
  }

  #technologyBlock__HtmlSvgPath1{
    fill: rgb(255, 255, 255, 0.1);
  }

  /* #technologyBlock__HtmlSvgPath2{
    fill: rgb(255, 255, 255, 0);
  }
  #technologyBlock__HtmlSvgPath3{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 755.9776611328125;
    stroke-dasharray: 755.9776611328125;
    animation: technologyBlock__HtmlSvgPath3Animation 2s ease-out forwards;
  } */

  #technologyBlock__HtmlSvgPath4{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 755.9776611328125;
    stroke-dasharray: 755.9776611328125;
    animation: technologyBlock__HtmlSvgPath3Animation 2s ease-out forwards;
  }


  @keyframes technologyBlock__HtmlSvgPath3Animation {
    0% {
      stroke-dashoffset: 755.9776611328125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }


  #technologyBlock__HtmlSvgPath1Hover{
    animation: technologyBlock__HtmlSvgPath1HoverAnimation 2s ease-out forwards;
  }

  #technologyBlock__HtmlSvgPath4Hover{
    animation: technologyBlock__HtmlSvgPath4HoverAnimation 2s ease-out forwards;
  }

  @keyframes technologyBlock__HtmlSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #41b883;
    }
  }
  @keyframes technologyBlock__HtmlSvgPath4HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #34495e;
    }
  }

  .technologyBlock__cssSVG polygon{
    fill: rgb(255, 255, 255, 0);
  }

  #technologyBlock__cssSvgPath1{
    fill: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__cssSvgPath2{
    fill: rgb(255, 255, 255, 0);
  }
  #technologyBlock__cssSvgPath3{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 298.31201171875;
    stroke-dasharray: 298.31201171875;
    animation: technologyBlock__cssSvgPath3Animation 2s ease-out forwards 0.1s ;
  }
  #technologyBlock__cssSvgPath4{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 314.61724853515625;
    stroke-dasharray: 314.61724853515625;
    animation: technologyBlock__cssSvgPath4Animation 2s ease-out forwards 0.1s ;
  }
  #technologyBlock__cssSvgPath5{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 350.12213134765625;
    stroke-dasharray: 350.12213134765625;
    animation: technologyBlock__cssSvgPath5Animation 2s ease-out forwards 0.1s ;
  }
  #technologyBlock__cssSvgPath6{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 918.7069091796875;
    stroke-dasharray: 918.7069091796875;
    animation: technologyBlock__cssSvgPath6Animation 2s ease-out forwards 0.1s ;
  }

  @keyframes technologyBlock__cssSvgPath3Animation {
    0% {
      stroke-dashoffset: 298.31201171875;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  @keyframes technologyBlock__cssSvgPath4Animation {
    0% {
      stroke-dashoffset: 314.61724853515625;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  @keyframes technologyBlock__cssSvgPath5Animation {
    0% {
      stroke-dashoffset: 350.12213134765625;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  @keyframes technologyBlock__cssSvgPath6Animation {
    0% {
      stroke-dashoffset: 918.7069091796875;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }

  #technologyBlock__cssSvgPath1Hover{
    animation: technologyBlock__cssSvgPath1HoverAnimation 2s ease-out forwards;
  }
  #technologyBlock__cssSvgPath2Hover{
    animation: technologyBlock__cssSvgPath2HoverAnimation 2s ease-out forwards;
  }
  #technologyBlock__cssSvgPath3Hover{
    fill: #ebebeb;
  }
  #technologyBlock__cssSvgPath4Hover{
    fill: #ebebeb;
  }
  #technologyBlock__cssSvgPath5Hover{
    fill: #ebebeb;
  }
  #technologyBlock__cssSvgPath6Hover{
    fill: #ebebeb;
  }

  @keyframes technologyBlock__cssSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #264de4;
    }
  }
  @keyframes technologyBlock__cssSvgPath2HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill:#2965f1;
    }
  }

  .technologyBlock__jsSVG rect, .technologyBlock__jsSVG path{
    fill: rgb(255, 255, 255, 0);
  }

  #technologyBlock__jsSvgPath1{
    fill: rgb(255, 255, 255, 0.1);
  }

  #technologyBlock__jsSvgPath2{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 2121.1474609375;
    stroke-dasharray: 2121.1474609375;
    animation: technologyBlock__jsSvgPath2Animation 2s ease-out forwards .2s;
  }

  @keyframes technologyBlock__jsSvgPath2Animation {
    0% {
      stroke-dashoffset: 2121.1474609375;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }

  #technologyBlock__jsSVG1{
    fill: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__jsSvgPath1Hover{
    animation: technologyBlock__jsSvgPath1HoverAnimation 2s ease-out forwards;
  }
  #technologyBlock__jsSvgPath2Hover{
    animation: technologyBlock__jsSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__jsSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #f7df1e;
    }
  }

  @keyframes technologyBlock__jsSvgPath2HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.8);
    }
    100% {
      fill: rgb(0, 0, 0, 1);
    }
  }

  /* Typescript SVG  */
  .technologyBlock__tsSVG rect, .technologyBlock__tsSVG path{
    fill: rgb(255, 255, 255, 0);
  }

  #technologyBlock__tsSvgPath1{
    fill: rgb(255, 255, 255, 0.1);
  }

  #technologyBlock__tsSvgPath2{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 2121.1474609375;
    stroke-dasharray: 2121.1474609375;
    animation: technologyBlock__tsSvgPath2Animation 2s ease-out forwards .1s;
  }

  @keyframes technologyBlock__tsSvgPath2Animation {
    0% {
      stroke-dashoffset: 2121.1474609375;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }

  #technologyBlock__tsSVG1{
    fill: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__tsSvgPath1Hover{
    animation: technologyBlock__tsSvgPath1HoverAnimation 2s ease-out forwards;
  }
  #technologyBlock__tsSvgPath2Hover{
    animation: technologyBlock__tsSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__tsSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #2D79C7;
    }
  }

  @keyframes technologyBlock__tsSvgPath2HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.8);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
    }
  }

  /*  */

  .technologyBlock__nodeSVG path{
    fill: rgb(255, 255, 255, 0);
  }
  #technologyBlock__nodeSvgPath0Hover{
    animation: technologyBlock__nodeSvgPath0HoverAnimation 3s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath0HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill:#f2f2f2;
      opacity: 0.9;
    }
  }
  #technologyBlock__nodeSvgPath1Hover{
    animation: technologyBlock__nodeSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.5);
    }
    100% {
      fill: #3b8739;
    }
  }
  #technologyBlock__nodeSvgPath2Hover{
    animation: technologyBlock__nodeSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath2HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #333;
    }
  }
  #technologyBlock__nodeSvgPath3Hover{
    animation: technologyBlock__nodeSvgPath3HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath3HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #66a060;
    }
  }
  #technologyBlock__nodeSvgPath4Hover{
    animation: technologyBlock__nodeSvgPath4HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath4HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #333;
    }
  }
  #technologyBlock__nodeSvgPath5Hover{
    animation: technologyBlock__nodeSvgPath5HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath5HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #6da75d;
    }
  }
  #technologyBlock__nodeSvgPath6Hover{
    animation: technologyBlock__nodeSvgPath6HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath6HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #55a344;
    }
  }
  #technologyBlock__nodeSvgPath7Hover{
    animation: technologyBlock__nodeSvgPath7HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath7HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #639d57;
    }
  }
  #technologyBlock__nodeSvgPath8Hover{
    animation: technologyBlock__nodeSvgPath8HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath8HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #333;
    }
  }
  #technologyBlock__nodeSvgPath9Hover{
    animation: technologyBlock__nodeSvgPath9HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath9HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #66a060;
    }
  }
  #technologyBlock__nodeSvgPath10Hover{
    animation: technologyBlock__nodeSvgPath10HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath10HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #66a060;
    }
  }

  #technologyBlock__nodeSvgPath0{
    fill: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__nodeSvgPath1{
    fill: rgb(255, 255, 255, 0.5);
    stroke: rgb(255, 255, 255, 0.5);
    stroke-width: 2;
    stroke-dashoffset: 1754.210693359375;
    stroke-dasharray: 1754.210693359375;
    animation: technologyBlock__nodeSvgPath1Animation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath1Animation {
    0% {
      stroke-dashoffset: 1754.210693359375;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.3);
    }
    100% {
      fill: rgb(255, 255, 255, 0.5);
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__nodeSvgPath2{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 2255.481689453125;
    stroke-dasharray: 2255.481689453125;
    animation: technologyBlock__nodeSvgPath2Animation 3s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath2Animation {
    0% {
      stroke-dashoffset: 2255.481689453125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__nodeSvgPath3{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 340.6608581542969;
    stroke-dasharray: 340.6608581542969;
    animation: technologyBlock__nodeSvgPath3Animation 3s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath3Animation {
    0% {
      stroke-dashoffset: 340.6608581542969;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__nodeSvgPath4{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 2157.63818359375;
    stroke-dasharray: 2157.63818359375;
    animation: technologyBlock__nodeSvgPath4Animation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath4Animation {
    0% {
      stroke-dashoffset: 2157.63818359375;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__nodeSvgPath5{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 1597.3229980468755;
    stroke-dasharray: 1597.322998046875;
    animation: technologyBlock__nodeSvgPath5Animation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath5Animation {
    0% {
      stroke-dashoffset: 1597.322998046875;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__nodeSvgPath6{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 632.5830078125;
    stroke-dasharray: 632.5830078125;
    animation: technologyBlock__nodeSvgPath6Animation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath6Animation {
    0% {
      stroke-dashoffset: 632.5830078125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__nodeSvgPath7{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 798.543212890625;
    stroke-dasharray: 798.543212890625;
    animation: technologyBlock__nodeSvgPath7Animation 2s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath7Animation {
    0% {
      stroke-dashoffset: 798.543212890625;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      fill: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__nodeSvgPath8{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 3118.3291015625;
    stroke-dasharray: 3118.3291015625;
    animation: technologyBlock__nodeSvgPath8Animation 2.5s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath8Animation {
    0% {
      stroke-dashoffset: 3118.3291015625;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__nodeSvgPath9{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 1451.7928466796875;
    stroke-dasharray: 1451.7928466796875;
    animation: technologyBlock__nodeSvgPath9Animation 3s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath9Animation {
    0% {
      stroke-dashoffset: 1451.7928466796875;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__nodeSvgPath10{
    fill: rgb(255, 255, 255, 0.9);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 2;
    stroke-dashoffset: 3957.053955078125;
    stroke-dasharray: 3957.053955078125;
    animation: technologyBlock__nodeSvgPath10Animation 3s ease-out forwards;
  }
  @keyframes technologyBlock__nodeSvgPath10Animation {
    0% {
      stroke-dashoffset: 3957.053955078125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }

  .technologyBlock__jqSvg path{
    fill: rgb(255, 255, 255, 0);
    stroke: rgb(255, 255, 255, 0);
  }

  #technologyBlock__jqSvgPath1{
    fill: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__jqSvgPath2{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 0.2;
    stroke-dashoffset: 403.9270935058594;
    stroke-dasharray: 403.9270935058594;
    animation: technologyBlock__jqSvgPath2Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__jqSvgPath2Animation {
    0% {
      stroke-dashoffset: 403.9270935058594;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__jqSvgPath3{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 0.1;
    stroke-dashoffset: 274.1688232421875;
    stroke-dasharray: 274.1688232421875;
    animation: technologyBlock__jqSvgPath3Animation 2s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__jqSvgPath3Animation {
    0% {
      stroke-dashoffset: 274.1688232421875;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.5);
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__jqSvgPath1Hover{
    animation: technologyBlock__jqSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__jqSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.1);
    }
    100% {
      fill: #fdfdfd;
    }
  }
  #technologyBlock__jqSvgPath2Hover{
    fill: #f2f2f2;
  }
  #technologyBlock__jqSvgPath3Hover{
    animation: technologyBlock__jqSvgPath3HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__jqSvgPath3HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #069c7e;
    }
  }

  .technologyBlock__ejsSvg path{
    fill: rgba(255, 255, 255, 0);
  }
  #technologyBlock__ejsSvgPath1{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 0.1;
    stroke-dashoffset: 148.29225158691406;
    stroke-dasharray: 148.29225158691406;
    animation: technologyBlock__ejsSvgPath1Animation 3s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__ejsSvgPath1Animation {
    0% {
      stroke-dashoffset: 148.29225158691406;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.5);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__ejsSvgPath1Hover{
    animation: technologyBlock__ejsSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__ejsSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #FF2D20;
    }
  }

  .technologyBlock-mongo path{
    fill: rgb(255, 255, 255, 0);
  }

  #technologyBlock__mongoSvgPath1{
    stroke-width: 0.1;
    stroke: rgb(255, 255, 255, 0.5);
    stroke-dashoffset: 76.32847595214844;
    stroke-dasharray: 76.32847595214844;
    animation: technologyBlock__mongoSvgPath1Animation 2s ease-out forwards 0.3s;
  }
  @keyframes technologyBlock__mongoSvgPath1Animation {
    0% {
      stroke-dashoffset: 76.32847595214844;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.25);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255, 0.5);
    }
  }
  #technologyBlock__mongoSvgPath2{
    stroke-width: 0.1;
    stroke: rgb(255, 255, 255, 0.7);
    stroke-dashoffset: 77.37744903564453;
    stroke-dasharray: 77.37744903564453;
    animation: technologyBlock__mongoSvgPath2Animation 2s ease-out forwards 0.3s;
  }
  @keyframes technologyBlock__mongoSvgPath2Animation {
    0% {
      stroke-dashoffset: 77.37744903564453;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.35);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255, 0.7);
    }
  }
  #technologyBlock__mongoSvgPath3{
    stroke-width: 0.1;
    stroke: rgb(255, 255, 255, 0.9);
    stroke-dashoffset: 21.755516052246094;
    stroke-dasharray: 21.755516052246094;
    animation: technologyBlock__mongoSvgPath3Animation 2s ease-out forwards 0.3s;
  }
  @keyframes technologyBlock__mongoSvgPath3Animation {
    0% {
      stroke-dashoffset: 21.755516052246094;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.45);
    }
    75%{
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__mongoSvgPath1Hover{
    animation: technologyBlock__mongoSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__mongoSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #599636;
    }
  }
  #technologyBlock__mongoSvgPath2Hover{
    animation: technologyBlock__mongoSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__mongoSvgPath2HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #6cac48;
    }
  }
  #technologyBlock__mongoSvgPath3Hover{
    animation: technologyBlock__mongoSvgPath3HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__mongoSvgPath3HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #c2bfbf;
    }
  }

  .technologyBlock__reactSvg circle{
    fill: rgb(255, 255, 255, 0);
  }

  .technologyBlock__reactSvg ellipse{
    fill: none;
  }

  #technologyBlock__reactSvgPath1{
    fill: rgb(255, 255, 255, 0.9);
    animation: technologyBlock__reactSvgPath1Animation 2s ease-out forwards;
  }

  @keyframes technologyBlock__reactSvgPath1Animation {
    0%{
      fill: rgb(255, 255, 255, 0);
    }

    100%{
      fill: rgb(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__reactSvgPath2{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-dashoffset: 50.1;
    stroke-dasharray: 50.1;
    animation: technologyBlock__reactSvgPath2Animation 1s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath2Animation {
    0% {
      stroke-dashoffset: 50.1;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__reactSvgPath3{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-dashoffset: 50.1;
    stroke-dasharray: 50.1;
    animation: technologyBlock__reactSvgPath3Animation 1s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath3Animation {
    0% {
      stroke-dashoffset: 50.1;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  #technologyBlock__reactSvgPath4{
    stroke: rgb(255, 255, 255, 0.9);
    stroke-dashoffset: 50.1;
    stroke-dasharray: 50.1;
    animation: technologyBlock__reactSvgPath4Animation 1s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath4Animation {
    0% {
      stroke-dashoffset: 50.1;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  #technologyBlock__reactSvgPath1Hover{
    animation: technologyBlock__reactSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #61dafb;
    }
  }
  #technologyBlock__reactSvgPath2Hover{
    animation: technologyBlock__reactSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath2HoverAnimation {
    0% {
      stroke: rgb(255, 255, 255, 0.9);
    }
    100% {
      stroke: #61dafb;
    }
  }
  #technologyBlock__reactSvgPath3Hover{
    animation: technologyBlock__reactSvgPath3HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath3HoverAnimation {
    0% {
      stroke: rgb(255, 255, 255, 0.9);
    }
    100% {
      stroke: #61dafb;
    }
  }
  #technologyBlock__reactSvgPath4Hover{
    animation: technologyBlock__reactSvgPath4HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__reactSvgPath4HoverAnimation {
    0% {
      stroke: rgb(255, 255, 255, 0.9);
    }
    100% {
      stroke: #61dafb;
    }
  }

  .technologyBlock__sqlSvg{
    fill: rgba(255, 255, 255, 0);
    stroke-width: 1;
    transition: all 0.5s;
  }

  .technologyBlock__sqlSvgGrad{
    stop-color: rgba(255, 255, 255, 0);
  }

  #technologyBlock__sqlSvgPath1{
    fill: url(#a);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 10;
    stroke-dashoffset: 11878.626953125;
    stroke-dasharray: 11878.626953125;
    animation: technologyBlock__sqlSvgPath1Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgPath1Animation {
    0% {
      fill: url(#a);
      stroke-dashoffset: 11878.626953125;
    }
    75%{
      stroke-dashoffset: 11080;
    }
    100% {
      stroke-dashoffset: 11080;
    }
  }

  #technologyBlock__sqlSvgGrad1{
    animation: technologyBlock__sqlSvgGrad1Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad1Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__sqlSvgGrad2{
    animation: technologyBlock__sqlSvgGrad2Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad2Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__sqlSvgGrad1Hover{
    animation: technologyBlock__sqlSvgGrad1HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad1HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #909ca9;
    }
  }
  #technologyBlock__sqlSvgGrad2Hover{
    animation: technologyBlock__sqlSvgGrad2HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad2HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #ededee;
    }
  }

  #technologyBlock__sqlSvgPath2{
    fill: url(#b);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 10;
    stroke-dashoffset: 11878.626953125;
    stroke-dasharray: 11878.626953125;
    animation: technologyBlock__sqlSvgPath2Animation 3s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgPath2Animation {
    0% {
      stroke-dashoffset: 11878.626953125;
    }
    75%{
      stroke-dashoffset: 11080;
    }
    100% {
      stroke-dashoffset: 11080;
    }
  }

  #technologyBlock__sqlSvgGrad3{
    animation: technologyBlock__sqlSvgGrad3Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad3Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__sqlSvgGrad4{
    animation: technologyBlock__sqlSvgGrad4Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad4Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__sqlSvgGradHover3{
    animation: technologyBlock__sqlSvgGrad3HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad3HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #939fab;
    }
  }
  #technologyBlock__sqlSvgGrad4Hover{
    animation: technologyBlock__sqlSvgGrad4HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad4HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #dcdee1;
    }
  }

  #technologyBlock__sqlSvgPath3{
    fill: url(#c);
    stroke: rgb(255, 255, 255, 0.9);
    stroke-width: 10;
    stroke-dashoffset: 11878.626953125;
    stroke-dasharray: 11878.626953125;
    animation: technologyBlock__sqlSvgPath3Animation 3.4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgPath3Animation {
    0% {
      stroke-dashoffset: 11878.626953125;
    }
    75%{
      stroke-dashoffset: 11080;
    }
    100% {
      stroke-dashoffset: 11080;
    }
  }

  #technologyBlock__sqlSvgGrad5{
    animation: technologyBlock__sqlSvgGrad5Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad5Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__sqlSvgGrad6{
    animation: technologyBlock__sqlSvgGrad6Animation 4s ease-out forwards 0.1s;
  }
  @keyframes technologyBlock__sqlSvgGrad6Animation {
    0% {
      stop-color: rgb(255, 255, 255, 0);
    }

    100% {
      stop-color: rgb(255, 255, 255, 0.9);
    }
  }
  #technologyBlock__sqlSvgGrad5Hover{
    animation: technologyBlock__sqlSvgGrad5HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad5HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #ee352c;
    }
  }
  #technologyBlock__sqlSvgGrad6Hover{
    animation: technologyBlock__sqlSvgGrad6HoverAnimation 4s ease-out forwards;
  }
  @keyframes technologyBlock__sqlSvgGrad6HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.9);
    }

    100% {
      stop-color: #a91d22;
    }
  }

  #technologyBlock__sqlSvgPath1Hover{
    fill: url(#a);
    stroke: rgb(255, 255, 252, 0.9);
    stroke-width: 10;
  }
  #technologyBlock__sqlSvgPath2Hover{
    fill: url(#b);
  }
  #technologyBlock__sqlSvgPath3Hover{
    fill: url(#c);
  }

  .technologyBlock__bootstrapSvg stop{
    stop-color: rgb(255, 255, 255, 0);
    transition: all 0.5s;
  }
  .technologyBlock__bootstrapSvg path{
    stroke: rgb(255, 255, 255, 0);
  }
  .technologyBlock__bootstrapSvg-b{
    stroke: rgb(255, 255, 255, 0);
    fill: rgba(255, 255, 255, 0);
  }
  #technologyBlock__bootstrapSvgPath1{
    stop-color: rgb(255, 255, 255, 0.1);
  }
  #technologyBlock__bootstrapSvgPath2{
    stop-color: rgb(255, 255, 255, 0.2);
  }

  #technologyBlock__bootstrapSvgPath3{
    fill: rgba(255, 255, 255, 0);
    stroke: rgb(255, 255, 255, 0);
    stroke-width: 10;
    stroke-dashoffset: 11878.626953125;
    stroke-dasharray: 11878.626953125;
    animation: technologyBlock__bootstrapSvgPath2Animation 3s ease-out forwards 0.2s;
  }
  @keyframes technologyBlock__bootstrapSvgPath2Animation {
    0% {
      stroke: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 11878.626953125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.45);
    }
    75%{
      stroke-dashoffset: 11080;
    }
    100% {
      stroke-dashoffset: 11080;
      fill: rgb(255, 255, 255, 0.9);
    }
  }

  #technologyBlock__bootstrapSvgPath1Hover{
    animation: technologyBlock__bootstrapSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__bootstrapSvgPath1HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.1);
    }
    100% {
      stop-color: #9013fe;
    }
  }
  #technologyBlock__bootstrapSvgPath2Hover{
    animation: technologyBlock__bootstrapSvgPath2HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__bootstrapSvgPath2HoverAnimation {
    0% {
      stop-color: rgb(255, 255, 255, 0.2);
    }
    100% {
      stop-color: #6610f2;
    }
  }
  #technologyBlock__bootstrapSvgPath3Hover{
    fill: rgb(255, 255, 255, 0.9);
  }

 .technologyBlock__gitSvg path{
  fill: rgb(255, 255, 255, 0);
 }
  #technologyBlock__gitSvgPath1Hover{
    animation: technologyBlock__gitSvgPath1HoverAnimation 2s ease-out forwards;
  }
  @keyframes technologyBlock__gitSvgPath1HoverAnimation {
    0% {
      fill: rgb(255, 255, 255, 0.9);
    }
    100% {
      fill: #f03c2e;
    }
  }

  #technologyBlock__gitSvgPath1{
    fill: rgba(255, 255, 255, 0);
    stroke: rgb(255, 255, 255, 0);
    stroke-width: 1;
    stroke-dashoffset: 11878.626953125;
    stroke-dasharray: 11878.626953125;
    animation: technologyBlock__gitSvgPath1Animation 3s ease-out forwards 0.3s;
  }
  @keyframes technologyBlock__gitSvgPath1Animation {
    0% {
      stroke: rgb(255, 255, 255, 0.9);
      stroke-dashoffset: 11878.626953125;
      fill: rgba(255, 255, 255, 0);
    }
    20%{
      fill: rgba(255, 255, 255, 0);
    }
    50% {

      fill: rgba(255, 255, 255, 0.45);
    }
    75%{
      stroke-dashoffset: 11080;
    }
    100% {
      stroke-dashoffset: 11080;
      fill: rgb(255, 255, 255, 0.9);
    }
  }

  /**/

  @media screen and (min-width: 768px) {
    .technologyBlock__showcaseContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (min-width: 1024px){
    .technologyBlock__showcaseContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .technologyBlock__itemContainer{
      width: 140px;
      height: 200px;
      margin-left: 140px;
      display: block;

    }

    .technologyBlock__itemContainer.first{
      margin-left: 0;
    }

    .technologyBlock__header {
      font-size: 2.5rem;

    }

    .technologyBlock__itemContainer h3{
      font-size: 1.8rem;
    }
  }




/* Projects block  */
.projects{
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2.5rem;
}

.contactTwo{
    display: inline;
    font-weight: 800;
    font-size: 1.9rem;
    float: right;
    margin-right: 4rem;
    cursor: pointer;
    transform: translate(0, -23px);
}

.contactTwo:after {
    position: absolute;
    height: 3px;
    margin: 0 auto;
    content: '';
    right: -22px;
    bottom: -15px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
    width: 135px;
    color: #000;
    background-color: var(--colour-primary);

}

.contactTwo:hover{
    color: var(--colour-primary)
}

.showGrid{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 10;
}

.showCase{
  margin-bottom: 6rem;
}

.picOne{
    background-image: url("../public/assets/images/thumbnail-project-1-small.webp");
}
.picTwo{
    background-image: url("../public/assets/images/thumbnail-project-2-small.webp");
}
.picThree{
    background-image: url("../public/assets/images/thumbnail-project-3-small.webp");
}
.picFour{
    background-image: url("../public/assets/images/thumbnail-project-4-small.webp");
}
.picFive{
    background-image: url("../public/assets/images/thumbnail-project-5-small.webp");
}
.picSix{
    background-image: url("../public/assets/images/thumbnail-project-6-small.webp");
}
.picSeven{
    background-image: url("../public/assets/images/thumbnail-project-6-small.webp");
}

.showPicture{
    background-size: cover;
    width: 336px;
    height: 196px;
    margin: 2rem auto;
    padding: 1px;
    border-radius: 20px;
    outline: 0;
    border: 0;
}

.subjects{
    display: inline;
    margin-right: 1.8rem;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    font-weight: normal;
}

.these{
    margin-bottom: 2rem;
}

.view{
    display: inline;
    color: white;
    margin-right: 5rem;
    cursor: pointer;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    margin-bottom: 5rem;
}

.view:hover{
    color: var(--colour-primary);
}

.underlineSmall{
    position: relative;
}

.underlineSmall:after {
    position: absolute;
    height: 3px;
    margin: 0 auto;
    content: '';
    left: 0px;
    bottom: -5px;
    width: 120%;
    color: #000;
    background-color: var(--colour-primary);
}

.view{
    font-size: 1.6rem;
}

.slot{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slot h3{
  font-size: 2.4rem;
}

@media screen and (min-width: 768px) {
    .showGrid{
        align-items: center;
        justify-content: left;
        /* width: 720px;  */

        gap: 20px;
    }

    .showPicture{
        width: 341px;
        height: 253px;
        margin-left: 0;
    }



    .view{
        font-size: 1.8rem;
    }
}
@media screen and (min-width: 1024px) {
    .showGrid{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .showPicture{
        width: 540px;
        height: 400px;
        margin-left: 0;
    }

    .slot{
        position: relative;

    }

    .blackBox{
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.0);
        transition: 0.3s all;
        border-radius: 20px;
        position: relative;

    }

    .slot:hover .blackBox{
        background-color: rgb(0, 0, 0, 0.8);
    }

    .buttons{
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translate(-50%, 0);
        display: grid;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        visibility: hidden;
        z-index: 10;
    }


    .slot:hover .buttons{
        visibility: visible;
    }

    .view.underlineSmall{
        display: block;
        text-decoration: none;
    }


    .contactTwo{
        margin-right: 2rem;
    }
}

@media screen and (min-width: 1280px) {
  .showGrid{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}


/* Footer  */
footer{
  background-color: rgb(255, 255, 252, 0.08);

}
.footer{
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.footer__linkContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
}

.footer__iconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__email{
  margin-bottom: 2rem;
  color: white;
  font-family: manrope;
  font-size: 2rem;
}

.footer__imageCarousel{
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 252, 0.5);
  height: 170px;
  width: 200px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.footer__imageReel{
  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  height: 340px;
  width: 200px;
  animation: carousel 12s ease-out;
  animation-iteration-count: infinite;
  transform: translateY(-170px);
  z-index: 0;
}

@keyframes carousel{
  0%   {transform: translateY(0);}
  15%   {transform: translateY(0);}
  25%  {transform: translateY(-170px);}
  40%  {transform: translateY(-170px);}
  50%  {transform: translateY(-340px);}
  90%  {transform: translateY(-340px);}
  100% {transform: translateY(0);}
}

.footer__photo{
  height: 170px;
  width: 100%;
  opacity: 0.9;
  filter: grayscale(1);
}
.footer__photo.one{
  background-size: 110%;
  background-image: url("/public/assets/images/5.jpg");
}
.footer__photo.two{
  background-size: 100%;
  background-image: url("/public/assets/images/4.jpg");
}
.footer__photo.three{
  background-size: 100%;
  background-image: url("/public/assets/images/me.png");
}

@media screen and (min-width: 768px) {
  .footer{
    margin-top: 6rem;
    justify-content: space-between;
    height: 170px;
    flex-direction: row;
  }

  .footer__iconContainer{
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .footer__iconContainer a .icon{
    margin-left: 0;
  }
}


/* Space block  */
.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
}

.floating{
  display: flex;
  position: relative;
  height: 400px;
  overflow: visible;
  position: absolute;
  left: 0%;
  z-index: -1;
}

.animationContainer{
  position: relative;
  z-index: 0;

}

.floating__canvas{
  bottom: 0%;
  left: 0;
  top: -132px;
  position: absolute;
  transition: all 2s;
  z-index: -0;
  opacity: 0.8;
}

.sceneBody{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 75px;
  perspective: 10em;
  perspective-origin: 50% calc(50% - 4em);
  height: 600px;

}

.scene{
  position: relative;
  transform-style: preserve-3d;

}

.cube{
  width: 2em;
  height: 2em;
  position: absolute;
  top: -0.5em;
  left: -1em;
  transition: all 2s;
  transform-style: preserve-3d;
  transform: rotateY(45deg);

}

.left, .right, .front, .back, .top{
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-image: url("/public/assets/nCube.svg"); */
  transform-style: preserve-3d;
}

.front{transform: translateZ(1em);}
.right{transform: rotateY(90deg) translateZ(1em);}
.back{transform: rotateY(180deg) translateZ(1em);}
.left{transform: rotateY(270deg) translateZ(1em);}
.top{ transform: translateY(-50%) rotateX(90deg) rotateZ(90deg);}

@media screen and (min-width: 768px) {
  .sceneBody{
    font-size: 95px;
  }
}